import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/zhannum/git/the-dark-between/src/layouts/DefaultLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Adam La Cour was a professor at `}<a parentName="p" {...{
        "href": "/The%20Basentia%20Omnia",
        "title": "The Basentia Omnia"
      }}>{`The Basentia Omnia`}</a>{`. He was killed in the `}<a parentName="p" {...{
        "href": "/Mehnateruna%20Incident",
        "title": "Mehnateruna Incident"
      }}>{`Mehnateruna Incident`}</a>{` when he took five students to `}<a parentName="p" {...{
        "href": "/Mehnateruna",
        "title": "Mehnateruna"
      }}>{`Mehnateruna`}</a>{` and inadvertantly enacted a ritual laid upon Mehnateruna's `}<a parentName="p" {...{
        "href": "/Spirit%20Sanctum",
        "title": "Spirit Sanctum"
      }}>{`Spirit Sanctum`}</a>{` by `}<a parentName="p" {...{
        "href": "/The%20Church%20of%20Aphothis",
        "title": "The Church of Aphothis"
      }}>{`The Church of Aphothis`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      